import React from "react"
import { Router } from "@reach/router"
import { useForm } from "react-hook-form"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { useFetch } from "../hooks/useFetch"
import SEO from "../components/seo"
import config from "../../config/website"
import logo from "../assets/images/logo-appsumo-way.png"

function AppSumoSuccess() {
  const { t } = useTranslation()
  return (
    <div className="text-center">
      <div className="svg-box">
        <svg className="circular orange-stroke">
          <circle
            className="path"
            cx="75"
            cy="75"
            r="50"
            fill="none"
            strokeWidth="5"
            strokeMiterlimit="10"
          />
        </svg>
        <svg className="checkmark orange-stroke">
          <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
            <path
              className="checkmark__check"
              fill="none"
              d="M616.306,283.025L634.087,300.805L673.361,261.53"
            />
          </g>
        </svg>
      </div>

      <h4>{t("Success!")}</h4>
      <p>
        {t(
          "Hello, sumo-ling! Your code was successfully redeemed. Use your newly created credentials to sign in."
        )}
      </p>
      <a href={config.appUrl}>{t("Sign In")}</a>
    </div>
  )
}

const AppSumoSignUp = props => {
  const { t } = useTranslation()
  const { handleSubmit, register, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: { code: props.code },
    shouldFocusError: true,
  })
  const { refetch, pending, response, success, error } = useFetch({
    url: config.appSumoSignUpApiUrl,
  })

  return (
    <div className="appsumo">
      <SEO title="Way <3 AppSumo" />
      <div className="appsumo-container container">
        <div className="appsumo-box row mx-auto">
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <img className="p-3" src={logo} alt="way_appsumo" />
          </div>
          <div className="col-md-6">
            <div className="appsumo-signup">
              {error && response && (
                <div className="alert alert-warning" role="alert">
                  {response.message}
                </div>
              )}
              {success ? (
                <AppSumoSuccess />
              ) : (
                <form onSubmit={handleSubmit(data => refetch(data))}>
                  <div className="form-group">
                    <input
                      tabIndex="0"
                      className="form-control"
                      name="name"
                      autoComplete="name"
                      placeholder={t("Name")}
                      ref={register({
                        required: t("Required"),
                      })}
                    />
                    {errors.name && (
                      <small className="form-text text-danger">
                        {errors.name.message}
                      </small>
                    )}
                  </div>

                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      autoComplete="email"
                      placeholder={t("AppSumo Email")}
                      ref={register({
                        required: t("Required"),
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: t("Invalid email address"),
                        },
                      })}
                    />
                    {errors.email && (
                      <small className="form-text text-danger">
                        {errors.email.message}
                      </small>
                    )}
                  </div>

                  <div className="form-group">
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      autoComplete="current-password"
                      placeholder={t("Password")}
                      ref={register({
                        required: t("Required"),
                        pattern: {
                          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
                          message: t(
                            "Your password must be 6-20 characters long and contain a minimum of 1 lower case letter, 1 upper case letter and 1 number. It must not contain spaces, fancy special characters, or emoji."
                          ),
                        },
                      })}
                    />
                    {errors.password && (
                      <small className="form-text text-danger">
                        {errors.password.message}
                      </small>
                    )}
                  </div>

                  <div className="form-group">
                    <input
                      className="form-control"
                      name="code"
                      placeholder={t("AppSumo Code")}
                      ref={register({
                        required: t("Required"),
                      })}
                    />
                    {errors.code && (
                      <small className="form-text text-danger">
                        {errors.code.message}
                      </small>
                    )}
                  </div>

                  <button
                    className="btn btn-primary btn-block"
                    disabled={pending}
                  >
                    Sign In
                  </button>
                </form>
              )}

              <hr />
              <div className="text-center">
                <span>
                  Issues?{" "}
                  <a
                    href="#chat"
                    onClick={() => {
                      if (window.$crisp) {
                        window.$crisp.push(["do", "chat:open"])
                      }
                    }}
                  >
                    Ask for help
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const AppSumoPage = () => (
  <Router>
    <AppSumoSignUp path="/appsumo" />
    <AppSumoSignUp path="/appsumo/:code" />
  </Router>
)

export default AppSumoPage
